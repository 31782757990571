.zadanie {
  padding: 5rem 15rem 3rem;
  @media screen and (max-width: 1800px) {
    padding: 2rem 1.5rem 3rem;
  }
  &__title {
    color: #fff;
    font-size: 32px;
    font-weight: 400;
    @media screen and (max-width: 1600px) {
      font-size: 26px;
    }
  }
  &__html {
    @media screen and (max-width: 1600px) {
      font-size: 18px;
    }
  }
  &__informacje,
  &__rozwiazuje {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  
  }
  &__informacje {
    @media screen and (max-width: 1600px) {
      align-items: center;
    }
  }
  &__video,
  &__pole {
    width: 64%;
  }
  &__pole {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // justify-content: flex-end;
  }

  &__infolink {
    display: block;
    text-align: center;
    img {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }

  &__opis {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    line-height: 28px;
    .zadanie__nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      > * {
        cursor: pointer;
      }
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rd { 
  @media screen and (max-width: 1600px) {
    margin-left: 11rem;
  }

}

.wrong {
  width: 50%;
  height: 700px;
  margin: 0 auto;
  display: flex;

  justify-content: center;
  align-items: center;
  .wrong-inside {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &:hover {
      opacity: 0.8;
    }
  }
  img {
    width: 8%;
   
    margin-right: 5rem;
    fill: #ff0016;
  }
  h2 {
    font-size: 3rem;
  }
  .swing {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float:left;
    // box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}
}
@keyframes swing {
  0% { transform: rotate(3deg); }
  100% { transform: rotate(-3deg); }
}


.z {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  // margin-top: 5rem;
  .drag {
    img {
      cursor: grab;
    }
  }
  &.z1 {
    .drop {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 600px;
    }
    .drag {
      width: 0%;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      flex-direction: row;
      flex-wrap: wrap;
      min-height: 632px;
      > div {
        margin: 25px;
      }
    }
  }
  &.z2 {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 700px;
    .cards {
      cursor: grab;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 21rem;
      height: 7.5rem;
      border: 2px solid white;
      border-radius: 10px;
      font-size: 18px;
      font-weight: 600;
    }
    .drops {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .dropme {
        position: absolute;
        border: 2px solid #fff;
        border-radius: 10px;
        width: 21rem;
      
        font-size: 20px;
        justify-content: center;
        text-align: center;
        padding: 25px;
      }
      .d1 {
       left: 16px;
        top: 14px;
      }
      .d2 {
        left: 256px;
        top: 14px;
      }
      .d3 {
        left: 498px;
        top: 14px;
      }
      .d4 {
        left: 16px;
        top: 119px;
      }
      .d5 {
        left: 256px;
        top: 119px;
      }
      .d6 {
        left: 498px;
        top: 119px;
      }
      .d7 {
        left: 16px;
        top: 226px;
      }
      .d8 {
        left: 256px;
        top: 226px;
      }
    }
    .over_dragme {
      height: 35rem;
      width: 100%;
      // position: relative;
    
    }
    .dragme {
      position: absolute;
      width: 20%;
      border: 2px solid #fff;
      font-size: 16px;
      padding: 5px 15px;
      height: 120px;
      display: flex;
    }
  }

  &.z3 {
    flex-direction: column;
    .pole {
      position: relative;
      .male_pole {
        position: absolute;
        width: 453px;
        top: 376px;
        height: 173px;
        left: 508px;
        border-radius: 20px;
      }
    }
    .drag {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      width: 61.5rem;
      background-color: #004a44;
      /* height: 11rem; */
      border: 2px solid white;
      border-radius: 10px;
      padding: 15px 30px;
      // @media screen and (min-width: 2000px ) {
      //   width: 70%;
      // }
      .cards {
        position: absolute;
      }
      .ule-txt {
        width: 35%;
      }
      .ule {
        // display: flex;
        // justify-content: space-between;
        // width: 70%;
      }
    }
    .MuiLinearProgress-root {
      width: 100%;
      height: 30px;
      margin-top: 3rem;
      background-color: transparent;
      border: 2px solid white;
      border-radius: 10px;
      margin-left: 0;
      @media screen and (min-width: 2000px ) {
        width: 70%;
      }
    }
    .zero {
      .MuiLinearProgress-barColorSecondary {
        background-color: #ff0016 !important;
      }  
    }
    .quater {
      .MuiLinearProgress-barColorSecondary {
        background-color: #B10000 !important;
      } 
    }
    .half {
      .MuiLinearProgress-barColorSecondary {
        background-color: #006A77 !important;
      }
    }
    .threequaters {
      .MuiLinearProgress-barColorSecondary {
        background-color: #53B9C6 !important;
      }
    }
    .full {
      .MuiLinearProgress-barColorSecondary {
        background-color: #50d597 !important;
      }
    }

    .smiles {
      width: 30%;
      position: absolute;
    right: 100px;
    bottom: 40px;
    @media screen and (max-width: 1600px) {
      right: 50px;
      bottom: 43px;
    }
    @media screen and (min-width: 2100px) {
      right: 250px;
      bottom: 40px;
      width: 28%;
    }
    }

    .rd {
      margin-left: 10rem;
    }
   
  }

  &.z4 {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .element {
      width: 25%;
      padding: 5px;

      img {
        max-width: 100%;
        border: 2px solid transparent;
        border-radius: 15px;
        padding: 15px;
        cursor: pointer;
        transition: 0.2s ease-out;
      }
      &.active {
        img {
          border-color: #fff;
          background-color: rgba(255, 255, 255, 0.13);
          border-radius: 15px;
          transition: 0.2s;
          -webkit-box-shadow: 3px 7px 20px 0px rgba(46, 46, 60, 0.28);
          -moz-box-shadow:    3px 7px 20px 0px rgba(46, 46, 60, 0.28);
          box-shadow:         3px 7px 20px 0px rgba(46, 46, 60, 0.28);
        }
      }
    }
  }

  &.z5 {
    position: relative;
    height: 700px;
    .drop {
      background: #fff;
      border-radius: 10px;
      width: 205px;
      height: 205px;
      transform: rotate(10deg);
      position: absolute;
      color: #004a44;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      text-align: center;
      padding: 10px;
    }
    .cards {
      background: #fff;
      border-radius: 10px;
      width: 205px;
      height: 205px;
      transform: rotate(10deg);
      position: absolute;
      color: #004a44;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      text-align: center;
      padding: 5px;
      -webkit-box-shadow: 7px 7px 32px -5px rgba(148, 148, 148, 1);
      -moz-box-shadow: 7px 7px 32px -5px rgba(148, 148, 148, 1);
      box-shadow: 7px 7px 32px -5px rgba(148, 148, 148, 1);
      cursor: grab;
    }
    // .drag {
    //   &1 {
    //     right: 0;
    //     top: 80%;
    //   }
    //   &2 {
    //     top: 0;
    //     left: 50%;
    //     transform: rotate(-10deg);
    //   }
    //   &3 {
    //     left: 0;
    //     top: 25%;
    //   }
    //   &4 {
    //     top: 80%;
    //     left: 25%;
    //     transform: rotate(-10deg);
    //   }
    // }
    .drop {
      &1 {
        left: 271px;
        top: 195px;
        transform: rotate(-10deg);
      }
      &2 {
        top: 256px;
        left: 2px;
        transform: rotate(-10deg);
      }
      &3 {
        left: 600px;
        top: 250px;
        transform: rotate(-10deg);
      }
      &4 {
        top: -50px;
        left: 357px;
        transform: rotate(-10deg);
      }
    }
  }

  &.z6 {
    display: flex;
    flex-direction: column;
    .okienko {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: -80px;
      left: 50%;
      transform: translate(-50%, 0);
      border: 2px solid white;
      padding: 15px;
      width: 26rem;
      border-radius: 10px;
      background-color: #004a44;
      label {
        font-size: 2rem;
        padding-right: 2rem;
      }
    }
    .css-input {
      width: 8rem;
      height: 8rem;
      padding: 5px;
      font-size: 20px;
      border-width: 2px;
      border-color: #ffffff;
      background-color: transparent;
      color: #ffffff;
      border-style: solid;
      border-radius: 10px;
      // box-shadow: 1px 0px 4px rgba(66,66,66,.63);
      // text-shadow: 0px 0px 5px rgba(66,66,66,.75);
      // margin-left: 2rem;
      text-align: center;
    }
    .css-input:focus {
      outline: none;
    }
  }

  &.z7 {
    position: relative;
    flex-direction: column;
    .rosliny {
      align-items: flex-end;
      justify-content: space-around;
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      img {
        cursor: pointer;
        width: 15%;
      }
    }
  }

  &.z8 {
    width : 89rem;
    position: relative;
    .cards {
      position: absolute;
      cursor: grab;
    }
    .path-svg {
      width: 100%;
    position: absolute;
    top: 221px;
    left: 38px;
    }
  }

  &.z9 {
    justify-content: center;
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-decoration: none;
    }
    h3 {
      font-size: 3rem;
      color: #50d597;
    }
  }
  &.z10 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 80%;
    .drop {
      margin-right: 2rem;
      
    }
    .kwiatki-cont {
    }
    .kwiatuszki {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      min-height: 300px;
      cursor: grab;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &.z11 {
    height: 600px;
    margin-top: 10rem;
    position: relative;
    .cards {
      position: absolute;
      cursor: grab;
      z-index: 11;
    }
    .drop {
      position: absolute;
    }
    .sciezka {
      position: absolute;
      width: 60rem;
      z-index: 10;
    }
    // .sciezki {
    //   position: absolute;
    //   top: 0;
    // left: -119px;
    // }
    .s1 {
      top: 40px;
      left: 98px;
    }
    .s2 {
      top: -79px;
      left: 115px;
    }
    .s3 {
      top: -59px;
      left: 89px;
    }
    .drop1 {
      left: 716px;
      top: 0;
    }
    .drop2 {
      left: 693px;
      top: 200px;
    }
    .drop3 {
      left: 716px;
      top: 400px;
    }

  }

  &.z12 {
    .piosenka {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;
      width: 50%;
      .znaki-row {
        display: flex;

        align-items: center;
        width: 100%;
        .znaki {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 33%;
          height: auto;
          min-height: 100%;
          .css-input12 {
            // margin-top: 1rem;
            margin-bottom: 3rem;
            padding: 2px;
            width: 60%;
            font-size: 14px;
            font-weight: 500;
            border-width: 2px;
            border-color: #004a44;
            background-color: #ffffff;
            color: #004a44;
            border-style: solid;
            border-radius: 5px;
            box-shadow: 0px 0px 3px rgba(66, 66, 66, 0.49);

            text-align: center;
          }
          .css-input12:focus {
            outline: none;
          }
        }
      }
    }
    .klucz_kodowy {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 50%;
      height: 100%;
      padding-bottom: 8rem;
      padding-top: 3rem;
      background-color: white;
      transform: rotate(10deg);
      color: #004a44;
      h2 {
        font-weight: 400;
        margin-bottom: 4rem;
      }
      .znaki-klucz {
        display: flex;
        flex-wrap: wrap;
        .znaki {
          width: 25%;
          min-height: 33%;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2rem;
          .iks {
            margin-top: 2rem;
          }
        }
      }
      img {
        transform: rotate(-5deg);
      }
    }
  }

  &.z13 {
    justify-content: center;
    .plusik {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 70%;
      }
    }
    h3 {
      font-size: 3rem;
      color: #50d597;
    }
  }
  &.z14 {
    flex-wrap: wrap;
    h3 {
      font-size: 2.5rem;
      @media screen and (max-width: 1600px) {
        font-size: 2.2rem;
      }
    }
    .labelClassname {
      color: white !important;
    }
    .checkbox-input:checked + .label-text:before {
      color: white !important;
    }
    .radio-input:checked + .label-text:before {
      color: #50D597 !important;
    }
    .checkbox-input,
    .radio-input {
      display: none;
    }
    .column {
      display: flex;
      flex-direction: column;
      width: 33%;
      margin-top: 5rem;
    }
  }

  &.z15 {
    justify-content: center;
    a {
      font-size: 3rem;
      color: #50d597;
      text-decoration: none;
    }
  }

  &.z16 {
    flex-direction: column;
    align-items: center;
    width: 80rem;
    .cards{
      display: flex;
      justify-content: space-between;
      
      img {
        margin-left: 1rem;
        width: 2rem;
      }
    }
    .drop {
    }
    .months {
      position: relative;
      width: 100%;
      height: 20rem;
      margin-top: 5rem;
      p {
        font-weight: 500;
        font-size: 2rem;
      }
      div {
        position: absolute;
        cursor: grab;
        // margin: 1rem;
        // padding: 15px;
        // border: 2px solid #004a44;
        // width: 20%;
        // border-radius: 10px;
        // font-size: 1.8rem;
      }
    }
    img {
      width: 65%;
    }
  }

  &.z17 {
    margin-top: 10rem;
    display: flex;
    // justify-content: space-between;
    .Wojtek {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40%;
      h3 {
        font-size: 3rem;
        text-align: center;
        @media screen and (max-width: 1600px) {
          font-size: 2.5rem;
        }
      }
      .pytanie {
        width: 47rem;
        button {
          border-radius: 0;
          background-color: #ffffff;
          color: #0d988c;
          margin: 0 2rem;
          padding: 1.5rem 8rem;
          transition: 0.3s ease;
        }
        button:hover {
          background-color: #0d988c;
          color: white;
          transition: 0.3s;
          opacity: 0.6;
        }
        .btn.active {
          background-color: #0d988c;
          color: white;
          transition: 0.3s;
          opacity: 1;
        }
      }
    }
    .info {
      padding: 5rem;
      margin-right: 5rem;
      width: 40%;
      background-color: white;
      color: #004a44;
      transform: rotate(5deg);
      p {
        font-size: 2rem;
        @media screen and (max-width: 1600px) {
          font-size: 1.7rem;
        }
      }
      h3 {
        font-size: 2.5rem;
        font-weight: 400;
      }
    }
    .glosnik {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img,
      h3 {
        cursor: pointer;
      }
      h3:hover,
      img:hover {
        opacity: 0.8;
      }
      img:hover + h3 {
        opacity: 0.8;
      }
      h3:hover + img {
        opacity: 0.8;
      }
    }
  }

  &.z18 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 60rem;
    .drop-cont {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 1900px) {
        justify-content: center;
      }
      .pozytywne,
      .negatywne {
        display: flex;
        position: absolute;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }
      .pozytywne {
        top: 400px;
        left: 1px;
      }
      .negatywne {
        top: 400px;
        left: 360px;
      }
    }
    .drop {
       
      width: 14rem;
      height: 14rem;
      border: 2px solid #004a44;
      border-radius: 10px;
      margin: 1rem;
    }
    .koszyki {
      display: flex;
    
    }

    .cards {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 14rem;
      height: 14rem;
      background-color: #004a44;
      border: 2px solid #004a44;
      border-radius: 10px;
      z-index: 3;
      cursor: grab;
      p {
        margin-bottom: 0;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }

  &.z19 {
    height: 70rem;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 2.5rem;
      color: #50d597;
    }
    .glosnik {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 50%;
      width: 50rem;
      .glos_img,
      h3 {
        cursor: pointer;
      }
      .glos_img:hover {
        opacity: 0.8;
      }
      .glos_img:hover + h3 {
        opacity: 0.8;
      }
      h3:hover {
        opacity: 0.8;
      }
      h3:hover + .glos_img {
        opacity: 0.8;
      }
    }
    .p1,
    .p2,
    .p3,
    .p4,
    .p5 {
      position: absolute;
    }
    .p1 {
      width: 5rem;
      top: -80px;
      left: 50%;
    }
    .p2 {
      width: 10rem;
      top: -13px;
      left: -44px;
    }
    .p3 {
      width: 12rem;
      top: 234px;
      left: -5%;
    }
    .p4 {
      width: 11rem;
      bottom: -119px;
      left: 59%;
    }
    .p5 {
      width: 15rem;
      top: -25px;
      right: -80px;
    }
  }
  &.z20 {
    justify-content: center;
    height: 700px;
    .pszczolki {
      width: 100%;
    }
    .ramka {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 568px;
      left: 50%;
      transform: translate(-50%, 0);
      border: 2px solid white;
      padding: 15px;
      width: 40rem;
      border-radius: 10px;
      background-color: #004a44;
     
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      label {
        font-size: 2rem;
        
      }
    }

    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
    .css-input {
      width: 8rem;
      height: 8rem;
      padding: 5px;
      font-size: 20px;
      border-width: 2px;
      border-color: #ffffff;
      background-color: transparent;
      color: #ffffff;
      border-style: solid;
      border-radius: 10px;
      // box-shadow: 1px 0px 4px rgba(66,66,66,.63);
      // text-shadow: 0px 0px 5px rgba(66,66,66,.75);
      // margin-left: 2rem;
      text-align: center;
    }
    .css-input:focus {
      outline: none;
    }
  }

  &.z21 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    // margin-top: 5rem;
    .slowo {
      display: flex;
      justify-content: center;
      transform: rotateY(-180deg) ;
      padding: 1rem 0;
      width: 25%;
      font-size: 2.2rem;
     
      
      
      .sl{
        border-radius: 15px;
        cursor: pointer;
        padding: 2rem;
        &:hover {
          color: #004a44;
          background-color: hsla(152, 61%, 57%, 0.397)
         
        } 
      }
      .jajeczko, .larwa, .pszczoła, .poczwarka {
        transition: 0.3s ease-out;
        &.active {
          color: #004a44;
          background-color: #50D597;
          transition: 0.3s;
          &:hover {
            background-color: hsla(152, 61%, 57%, 0.397)
          }
        }
      }
      .miód, .pasieka, .klapki, .propolis, .kószka, .królowa, .podkurzacz, .ul, .rodzina, .rękawice, .kwiat, .bartnik {
        transition: 0.3s ease-out;
      &.active {
        color: white;
        background-color: rgb(230, 88, 99);
        transition: 0.3s;
        &:hover {
          opacity: 1;
        }
      }
    }
    }
  }


  &.z22 {
    height: 700px;
    position: relative;
    .card {
      display: flex;
      flex-direction: column;
     position: absolute;
      align-items: center;
      justify-content: space-around;
      width: 20rem;
       height: 20rem;
      margin: 2rem;
      background-color: #004a44;
      border-radius: 20px;
      padding: 2rem;

      -webkit-box-shadow: 3px 7px 20px 0px rgba(46, 46, 60, 0.28);
      -moz-box-shadow:    3px 7px 20px 0px rgba(46, 46, 60, 0.28);
      box-shadow:         3px 7px 20px 0px rgba(46, 46, 60, 0.28);
    cursor: pointer;
    border: 5px solid transparent;
      img {
        
        min-height: 50%;
      }
      p{
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
    .c1 {
      top: 280px;
      left: 20px;
    // @media screen and (max-width: 1900px) {
    //   left: 0%;
    // }
    
    p {
      text-align: center;
    }
    }
    .c2 {
      top: 20px;
      left: 20px;
 
    }
    .c3 {
      top: 50px;
      left: 280px;
    }
    .c4 {
      top: 310px;
      left: 280px;
 
    }
    .c1.active, .c2.active, .c3.active, .c4.active {
      border: 5px solid #50d597;
    } 

    .input-box {
      position: absolute;
      bottom: 4rem;
      width: 100%;
     
      label {
        margin-right: 2rem;
      }
    }
    .css-input {
      
     
      height: 4rem;
      padding: 5px;
      font-size: 20px;
      border-width: 2px;
      border-color: #ffffff;
      background-color: transparent;
      color: #ffffff;
      border-style: solid;
      border-radius: 10px;
      // box-shadow: 1px 0px 4px rgba(66,66,66,.63);
      // text-shadow: 0px 0px 5px rgba(66,66,66,.75);
      // margin-left: 2rem;
      text-align: center;
      
    }
    .css-input:focus {
      outline: none;
    }
  }

  &.z26 {
    position: relative;
    height: 700px;
    .cards {
      position: absolute;
      width: 15rem;
      height: 15rem;
      background-color: #004a44;
      border: 5px solid transparent;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: grab;
      img {
        width: 60%;
      }
    }
    .drop {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 15rem;
      height: 15rem;
      border: 5px solid #004a44;
      border-radius: 15px;
    }
    .drop1 {
      top: 1;
      left: 1;
    }
    .drop2 {
      top: 400px;
      left: 16px;
    }
    .drop3 {
      top: 186px;
      left: 253px;  
    }
    .drop4 {
      top: 20px;
      left: 700px;
    }
    .drop5 {
      top: 460px;
      left: 600px;
    }
  }
}

.popup-text {
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: justify;
}

.popup-div {
  display: flex;
  align-items: center;
  top: 10px;
  left: 37px;
  img {
    height: 80%;
    max-width: 30%;
  }
}

.popup-content {
  display: flex;
  flex-direction: row;
  top: 10px;
  left: 37px;
  background: #004a44;

  height: 30rem;
  padding: 5px;
  border: 3px solid white;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  top: 50% !important;
  left: 33% !important;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  width: 60rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  border-radius: 10px;
  @media screen and (min-width: 1900px) and (max-width: 2100px) {
    top: 40% !important;
  }
  @media screen and (min-width: 2101px) {
    top: 33% !important;
  }
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  background-color: wheat;
  opacity: 0.3;
}
[data-popup="tooltip"].popup-overlay {
  background-color: wheat;
  opacity: 0.3;
}

.popup-arrow {
  // filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  // color: #fff;
  // stroke-width: 2px;
  // stroke: #d7d7d7;
  // stroke-dasharray: 30px;
  // stroke-dashoffset: -54px;
  display: none;
}
