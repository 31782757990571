// $width: 2500px;
// $height: 1400px;

.scroll-container {
  height: calc(100vh - 10rem);
  .scroll-paragraph {
    padding: 0rem 15rem;
    @media screen and (max-width: 1800px) {
      padding: 0rem 1.5rem;
    }
    @media screen and (max-width: 1700px) {
      position: absolute;
    }
  }
}

.prisma {
  display: flex;
  justify-content: center;

  align-items: center;

  width: 100vw;
  height: calc(100vh - 10rem);
  background: #0d988c;
}

.trawka {
  display: flex;
  justify-content: center;
  align-items: center;

  .road-container {
    position: relative;
  }

  .mapa {
    width: 100%;
    z-index: 2;
  }
}

.start {
  &__trigger {
    position: absolute;
    background: rgba(0, 74, 68, 0.75);
    color: #fff;
    padding: 3rem 4rem;
    border-radius: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    transform: translate(-50%, -50%);
    transition: 0.3s all;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
    p {
      margin: 0 0 2rem;
      text-align: center;
    }
    &.pszczoly {
      left: 46%;
      top: 12%;
    }
    &.szklarnia {
      left: 74%;
      top: 34%;
    }
  }
}
