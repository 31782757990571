header {
  background: #0d988c;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 2rem 15rem;
  height: 10rem;
  z-index: 999;
  @media screen and (max-width: 1800px) {
    padding: 2rem 1.5rem;
  }
  @media screen and (max-width: 1700px) {
    position: absolute;
  }
}
