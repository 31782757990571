
body {
  /* height: 100vh;
 width: 100vw; */
  /* overflow: hidden; */
  /* transition: 1.2s all;*/
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #0d988c;
  min-width: 1340px;
  @media screen and (max-width: 1100px)  {
    min-width: 320px;
  }
}

a {
  color: #fff;
}

button {
  background: none;
  border: none;
  outline: none;
  border: 2px solid #fff;
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
  line-height: 16px;
  padding: 6px 18px;
  color: #fff;
  border-radius: 20px;
  transition: 0.3s all;
  width: auto;
  cursor: pointer;
  &.squared {
    border-radius: 0;
  }


}

.dalej {
  &:hover {
    background: #fff;
    color: #0d988c;
    .arrow {
      margin-left: 10px;
      border-color: #0d988c;
    }
  }
}

.arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: 0.3s all;
  &.arrow-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}
.wroc:hover {
  .arrow-left {
    margin-right: 10px;
  }
}

.powrot {
  position: absolute;
  height: 5rem;
  width: 14rem;


  top: 4rem;
  right: 15rem;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  cursor: pointer;
  flex-direction: row;
  // &:before {
  //   content: "x";
  //   font-size: 32px;
  //   line-height: 32px;
  //   display: inline-block;
  //   margin-right: 12px;
  // }
  &:hover{
    opacity: 0.8;
  }
  img {
    width: 2rem;
  }
}



.zaslepka {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  text-align: justify;
  flex-direction: column;
  h3 {
    margin-top: 5rem;
   line-height: 2.5rem;
  }
  img {
    width: 100%;
  }
}
