.prisma {
  .plus,
  .minus {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 5rem;
    width: 5rem;
    bottom: 1em;
    z-index: 999;
    background: #0D988C;
    border: 1px solid #fff;
    color: #fff;
    font-size: 45px;
    line-height: 5rem;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s all;
    padding: 0;
    border-radius: 0;
    -webkit-box-shadow: 3px 7px 20px 0px rgba(46, 46, 60, 0.28);
    -moz-box-shadow:    3px 7px 20px 0px rgba(46, 46, 60, 0.28);
    box-shadow:         3px 7px 20px 0px rgba(46, 46, 60, 0.28);
    z-index: 10;
    &:hover {
      color: #0d988c;
      background: #fff;
    }
    &:focus {
      outline: none;
    }
  }

  .plus {
    left: 3rem;
  }
  .minus {
    left: 10rem;
  }
}
