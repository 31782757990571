.pasieka {
  padding: 5rem 15rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  @media screen and (max-width: 1800px) {
    padding: 2rem 1.5rem 3rem;
  }
  &__title {
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 32px;
    font-weight: 400;
  }

  &__budowa {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    // border: 1px dashed red;
  }
}

.elementy {
  background: #004a44;
  border: 3px solid #fff;
  border-radius: 12px;
  padding: 29px 26px 25px 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1100px;
  min-height: 153px;
  &__title {
    font-size: 32px;
    text-transform: uppercase;
    margin-right: 16px;
  }
  &__lista {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    img {
      margin: 0 16px;
    }
    div[style*="position: static;"] {
      height: 100px;
      max-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 80%;
        max-height: 100%;
      }
    }
  }
}
