.odznaki {
  background: #004a44;
  border: 3px solid #fff;
  border-radius: 12px;
  padding: 29px 26px 25px 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  // width: 1100px;
  // position: absolute;
  // left: 50%;
  // bottom: 0;
  // margin-left: -550px;
  &__title {
    font-size: 32px;
    text-transform: uppercase;
    margin-right: 16px;
  }
  &__lista {
    display: flex;
    flex-direction: row;
    img {
      margin: 0 16px;
    }
  }
}
