.moja_pasieka {
  left: 58%;
  top: 14%;
  min-width: 280px;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  padding: 3rem;
  left: 0;
  top: 40px;
  font-size: 32px;
  line-height: 32px;
  z-index: 11;
  img {
    width: 300px;
    margin-bottom: 60px;
  }
  &__title {
    font-size: 90px;
    line-height: 80px;
    font-weight: normal;
    margin: 20px 0 20px;
  }
  button {
    margin-top: 40px;
  }
  .modal-p {
    text-align: center;
  }
}

.numerek {
  position: absolute;
  max-width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 3px solid rgba(255, 255, 255, 0.5);
  color: #1ca274;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  font-size: 24px;
  transition: 0.6s all;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  span {
    transition: 0.6s all;
    opacity: 0;
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    border: 3px solid #1ca274;
    border-radius: 20px;
  }
  &__opis {
    display: none;
    color: #1ca274;
    padding-right: 24px;
    margin-left: 12px;
    line-height: 42px;
    @media screen and (max-width: 1600px) and (min-width: 1450px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1449px)  {
      font-size: 16px;
    }
  }
  &.aktywne {
    border: 3px solid rgba(255, 255, 255, 1);
    background: #1ca274;
    cursor: pointer;
    span {
      opacity: 1;
      color: #fff;
    }
    &:hover {
      background: #fff;
      span {
        color: #1ca274;
      }
      .numerek__opis {
        display: block;
      }
      max-width: none;
    }
  }
  &.done {
    background: #fff;
    border: 3px solid rgba(255, 255, 255, 1);
    span {
      opacity: 1;
      color: #1ca274;
    }
  }

  &.z1 {
    left: 39.3%;
    top: 58.8%;
  }
  &.z2 {
    left: 52.4%;
    top: 66.3%;
  }
  &.z3 {
    left: 60.6%;
    top: 38.8%;
  }
  &.z4 {
    left: 39%;
    top: 39%;
  }
  &.z5 {
    left: 54.4%;
    top: 51%;
  }
  &.z6 {
    left: 15.2%;
    top: 36.2%;
  }
  &.z7 {
    left: 10.6%;
    top: 24%;
  }
  &.z8 {
    left: 20%;
    top: 12.2%;
  }
  &.z9 {
    left: 14%;
    top: 15%;
  }
  &.z10 {
    left: 28.2%;
    top: 29.6%;
  }
  &.z11 {
    left: 63.6%;
    top: 65%;
  }
  &.z12 {
    left: 60%;
    top: 53%;
  }
  &.z13 {
    left: 68%;
    top: 55.8%;
  }
  &.z14 {
    left: 70.4%;
    top: 69.7%;
  }
  &.z15 {
    left: 75.8%;
    top: 71.4%;
  }
  &.z16 {
    left: 66%;
    top: 41.8%;
  }
  &.z17 {
    left: 66%;
    top: 29%;
  }
  &.z18 {
    left: 76.5%;
    top: 30%;
  }
  &.z19 {
    left: 78.4%;
    top: 12.6%;
  }
  &.z20 {
    left: 35.5%;
    top: 15.3%;
  }
  &.z21 {
    left: 36%;
    top: 23.6%;
  }
  &.z22 {
    left: 42.5%;
    top: 21%;
  }
  &.z23 {
    left: 46.7%;
    top: 17%;
  }
  &.z24 {
    left: 17.9%;
    top: 59%;
  }
  &.z25 {
    left: 18.4%;
    top: 67.6%;
  }
  &.z26 {
    left: 26.6%;
    top: 60%;
  }
}
