.instructions {
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  width: 40%;
  margin: 10rem auto;
  height: 500px;
  position: relative;
  @media screen and (max-width: 1600px) {
    width: 50%;
  }
  @media screen and (min-width: 2000px) {
    width: 30%;
  }
  .plastry {
    position: absolute;
    bottom: -60px;
    left: -45px;
    width: 20%;
  }
  .taniec {
    position: absolute;
    width: 15%;
    top: -100px;
    right: -50px;
  }

  li {
    font-size: 22px;
  }
}

.instruction-inside {

  h2 {
    font-weight: 400;
  }
}


.instrukcja {
  padding: 5rem 15rem 3rem;
  @media screen and (max-width: 1800px) {
    padding: 2rem 1.5rem 3rem;
  }
  &__title {
    color: #fff;
    font-size: 32px;
    font-weight: 400;
    @media screen and (max-width: 1600px) {
      font-size: 26px;
    }
  }
  &__html {
    @media screen and (max-width: 1600px) {
      font-size: 18px;
    }
  }
  &__informacje,
  &__rozwiazuje {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  
  }
  &__informacje {
    @media screen and (max-width: 1600px) {
      align-items: center;
    }
  }
  &__video,
  &__pole {
    width: 64%;
  }
  &__pole {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // justify-content: flex-end;
  }

  &__infolink {
    display: block;
    text-align: center;
    img {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }

  &__opis {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 15px;
    line-height: 28px;
    .instrukcja__nav {
      display: flex;
       justify-content: flex-end;
      align-items: center;
      width: 100%;
      > * {
        cursor: pointer;
      }
    }
  }


 
}


.wroc {
  margin-right: 5rem;
  font-size: 20px;
}

.dalej {
  min-width: 14rem;
}