.mainCol.active .MuiTablePagination-root {
  color: #F8B227;
}

.mainCol.active .MuiSelect-icon {
  color: #F8B227;
}

.mainCol.active .MuiButtonBase-root {
  color: #F8B227;
}

.mainCol.active .MuiSwitch-track {
  color: #F8B227;
}

.mainCol.active.active .MuiTableCell-stickyHeader {
  background-color: #444444;
}

.mainCol.active .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track {
  background-color: #F8B227;
  opacity: 0.2;
}

  .mainCol.active .MuiFormControlLabel-label.Mui-disabled {
color: #F8B227;
}

.mainCol.active .MuiFormHelperText-root.Mui-disabled {
  color: #F8B227;
}


/* ********************************************* */
.mainCol.active .MuiInputBase-input.Mui-disabled {
  color: white;
}
.mainCol.active .MuiInputBase-input {
  color: #F8B227;
}

/* floating button black words */
 .mainCol.active span.MuiSpeedDialAction-staticTooltipLabel {
  color: black !important;
} 
/* 
.mainCol.active .MuiButtonBase-root svg {
 fill: black !important;
} */

/* .mainCol.active .MuiPaper-root {
  background-color: #444444;
} */


.mainCol.active .MuiBadge-colorSecondary {
  color: black;
}

.iAUBuK {
 margin-top: 4rem !important;
  margin-bottom: -2rem;
}

.iAUBuK .ilsaEp {
  font-weight: 500;
}

.mainCol.active .fuTBkt {
  font-weight: 500;
}

.iAUBuK .fTiioL {
  margin-bottom: -1rem;
}

/* .mainCol.active  ul {
  background-color: #222222 !important;
} */


/* INPUT FOCUSSED */
.mainCol.active .hBYcfQ.hBYcfQ .MuiFilledInput-root.Mui-focused{
  background-color: #444444 !important;
}

.mainCol.active .MuiFormLabel-root.Mui-focused {
 color: #F8B227;
}

.mainCol.active .eblwZQ.eblwZQ .MuiFilledInput-root.Mui-focused {
  background-color: #444444;
}

.mainCol.active .MuiMenu-paper.MuiPaper-rounded {
  background-color: black !important;
}


/* margint top na pulpicie */
/* .MuiGrid-spacing-xs-2  {
  margin-top: 2rem !important;
}
 */


 .drKleb.drKleb.MuiGrid-spacing-xs-2 {
   margin-top: 0rem !important;
 }

 .ccTUyx.ccTUyx.MuiGrid-spacing-xs-2 {
  margin-top: 0rem !important;
 }


 /* input wybierz placowke bazy */
 .klQqVC.klQqVC .MuiFilledInput-root.Mui-focused {
   background-color: #444444 !important;
 }

/* .mainCol.active .MuiPaper-root.MuiPaper-rounded {
   background-color: #222222 ;
   color: #F8B227 ;
 }  */

 
 
 .mainCol.active .MuiFilledInput-root.Mui-focused {

  background-color: #444444 !important;
  
}

.MuiFilledInput-inputMultiline {
  padding-top: 1rem !important;
}

.mainCol.active .MuiListItemIcon-root {
 color: #F8B227;
}

.ihqbKZ button{
  margin-bottom: 0.7rem;
}

.contrast-on .MuiPaper-root.MuiPaper-rounded {
  background-color: #222222 ;
  color: #F8B227  ;
}

.contrast-on .MuiPickersCalendarHeader-iconButton {
  background-color: #222222;
}

.contrast-on .MuiPickersCalendarHeader-switchHeader {
  color: #F8B227;
}

.contrast-on .MuiIconButton-root {
  color: #F8B227;
}

.contrast-on .MuiPickersCalendarHeader-dayLabel {
  color: #F8B227;
}


/* .MuiAutocomplete-popper {

} */

@media screen and (max-width: 750px) {
  .bvREyM.bvREyM{
    height: 46% !important;
  }
  .jNnBKa.jNnBKa {
    height: 46% !important;
  }
  .kORYQl.kORYQl {
    height: 46% !important;
  }
  .hLiyYk.hLiyYk {
    height: 46% !important;
  }
  .kPszTA.kPszTA {
    height: 46% !important;
  }
  .bRoZff.bRoZff {
    height: 46% !important;
  }
}